<template>
  <v-row>
    <v-col cols="12" class="d-flex">
      <v-btn-toggle
        class="d-flex align-start"
        v-bind:class="{ 'class-custom-time': showTime }"
        v-model="toggle_exclusive"
      >
        <v-btn disabled id="custom-disabled" style='height: 40px !important'>
          {{ label }}
        </v-btn>
      </v-btn-toggle>

      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        v-if="!showTime"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="computedDateFormattedMomentjs"
            class="centered-input"
            outlined
            dense
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="dateNow" @input="menu = false" class="menu-picker"></v-date-picker>
      </v-menu>
      <vue-timepicker
        class="class-custom-time"
        v-if="showTime"
        v-model="valueTime"
        input-width="120px"
        format="HH:mm"
        close-on-complete
        hide-clear-button
      ></vue-timepicker>
    </v-col>
  </v-row>
</template>

<script>
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import moment from 'moment'
export default {
  components: {
    VueTimepicker,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    date: {
      type: String,
      default: moment().format('YY-MM-DD'),
    },
    time: {
      type: String,
      default: '00:00:00',
    },
    showTime: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menu: false,
      // time: '00:00:00',
      // date: moment().format('YY-MM-DD'),
      toggle_exclusive: 0,
      dateNow: moment(new Date('20' + this.date)).format('YYYY-MM-DD')
    }
  },
  computed: {
    value: {
      get() {
        const conv = moment(new Date(this.date)).format('YYYY-MM-DD')
        return conv === 'Invalid date' ? this.date : conv
      },
      set(date) {
        this.$emit('setValue', date)
      },
    },
    valueTime: {
      get() {
        return this.time
      },
      set(time) {
        this.$emit('setValue', time)
      },
    },
    computedDateFormattedMomentjs () {
        this.$emit('setValue', this.dateNow.toString().substring(2))
        return this.dateNow ? moment(this.dateNow).format('YY-MM-DD') : ''
      },
  }
}
</script>

<style scoped>
  #custom-disabled.v-btn--disabled {
    color: #424242 !important;
    opacity: 0.8;
    background-color: #ffff !important;
  }
  .centered-input >>> input {
    text-align: center;
  }
  .class-custom-time {
    margin-top: -44px !important;
  }

  @media (max-width: 550px) {
    .v-menu__content:has(> .menu-picker){
      left: 15vW !important;
    }
  }
</style>
